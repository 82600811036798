import React from 'react'
import { Divider, Alert, Image, Space } from 'antd';
import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { useStreaming } from '../../../utils/hooks';
import Loading from './Loading';

export default function ModelOutputImage() {
  const { assistantImageURL, isLoading } = useStreaming()

  const onDownload = (src) => {
    fetch(src)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      });
  };
    
  return (
    <div id="image_output_div">
      {(isLoading || assistantImageURL) ? <Divider/> : <></>}
      {assistantImageURL ? <Alert message="현재창을 벗어나면 이미지를 다시 확인할 수 없습니다. 필요한 경우 다운로드하세요." type="warning" style={{marginBottom: 16, width: "80%"}}/> : <></>}
      {isLoading ? 
        <Loading />
        : 
        <Image
          width={200}
          src={assistantImageURL}
          preview={{
            toolbarRender: (
              _,
              {
                transform: { scale },
                actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
              },
            ) => (
              <Space style={{fontSize: 32}}>
                <DownloadOutlined onClick={() => onDownload(assistantImageURL)} />
                {/* <SwapOutlined rotate={90} onClick={onFlipY} />
                <SwapOutlined onClick={onFlipX} />
                <RotateLeftOutlined onClick={onRotateLeft} />
                <RotateRightOutlined onClick={onRotateRight} /> */}
                <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
              </Space>
            ),
          }}
        />
        // <img id="image_output" src={assistantImageURL} />
      }
    </div>
  )
}