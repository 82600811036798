import { create } from "zustand";

const useFrame = create((set) => ({
    iframeSrc: "",
    setIframeSrc: (src) => set({
        iframeSrc: src
    })
}))


export {
    useFrame
}