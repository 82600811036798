import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks"
import Cookies from "js-cookie";
import { useState, useEffect } from "react";


const ProtectedRoute = ({children}) => {
    const [tokenValid, setTokenValid] = useState()
    // const { token } = useAuth();
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(() => {
        const isTokenValid = async () => {
            const token = Cookies.get("refreshToken")
            const customHeaders = new Headers();
            customHeaders.append("Authorization", "Bearer " + token);
        
            const requestOptions = {
                method: "GET",
                headers: customHeaders,
                redirect: "follow"
            };
        
            const tokenValidFlag = await fetch(process.env.REACT_APP_AUTH_BASE_URL + '/user/me', requestOptions)
                .then((response) => {
                if (response.status == 200) {
                    setTokenValid(true)
                } else {
                    setTokenValid(false);
                    navigate('/signin')
                }
                });

            return tokenValidFlag
        }
        isTokenValid()
    }, [])

    return children
}

export default ProtectedRoute;