import UserInputToImage from "../components/UserInputToImage";
import ModelOutputImage from "../components/ModelOutputImage";


const ImageGeneratorView = () => {

  return (
    <>
        <UserInputToImage />
        <ModelOutputImage />
    </>
  );
};

export default ImageGeneratorView;