import { useAuth } from '../../../utils/hooks';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined, EditOutlined } from '@ant-design/icons';
import { useFrame } from '../../../utils/store.js';

export default function UserDropdown() {    
    const { user, onLogout } = useAuth();
    const { iframeSrc, setIframeSrc } = useFrame()
    const staticDropdownMenuTtems = [
      {
        key: '0',
        danger: true,
        label: '로그아웃',
        onClick: onLogout,
        style: {
            'background': 'red',
            'color': 'white',
            'margin': '-4px',
            'border': 'solid',
            'borderRadius': '4px'
        }
      }, 
      {
        key: '1',
        danger: false,
        label: '비밀번호 변경',
        onClick: () => {
            setIframeSrc('/password')
        },
        style: {
            'background': 'white',
            'marginTop': '4px',
            'margin': '-4px',
            'border': 'none',
            'borderRadius': '0px'
        }
      }, 
      {
        key: '2',
        danger: false,
        label: <span>피드백 <EditOutlined/></span>,
        onClick: () => {window.open('https://forms.gle/V59WfHY9j8LQv3V58', '_blank').focus()},
        style: {
            'background': 'white',
            'marginTop': '4px',
            'margin': '-4px',
            'border': 'none',
            'borderRadius': '0px'
        }
      }
    ];

    return (
        <div>
            <Dropdown menu={ { items: staticDropdownMenuTtems }} placement='topLeft' align={{ offset: [-10, 20] }} trigger={["click"]}>
                <a onClick={(e) => {
                    e.preventDefault()
                }}>
                <Space id='dropdown_space'>
                    {user.name}
                    <DownOutlined />
                </Space>
                </a>
            </Dropdown>
        </div>
    )
}
