import { useContext, createContext } from "react"

const AuthContext = createContext(null);
const StreamingContext = createContext(null);

const useAuth = () => {
    return useContext(AuthContext);
};

const useStreaming = () => {
    return useContext(StreamingContext)
}
export { AuthContext, StreamingContext, useAuth, useStreaming };