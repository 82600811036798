import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LoginView from "./views/login/container";
import { AuthProvider, StreamingProvider } from "./utils/providers";
import ChatView from "./views/chat/container";
import ProtectedRoute from "./utils/routes";
import InProgressView from "./views/inprogress/container";
import ImageGeneratorView from "./views/image-generator/container";
import PasswordView from "./views/password/container"

const AppRouter = () => {
  return (
    <Router>
        <AuthProvider>
            <StreamingProvider>
                <Routes>
                    <Route path="/signin" element={<LoginView />} exact />
                    <Route path="/" element={
                        <ProtectedRoute>
                            <ChatView />
                        </ProtectedRoute>
                        } exact/>
                    <Route path="/ai-tools/image-generator" element={
                        <ProtectedRoute>
                            <ImageGeneratorView />
                        </ProtectedRoute>
                        } exact/>                
                    <Route path="/in-progress" element={
                        <ProtectedRoute>
                            <InProgressView />
                        </ProtectedRoute>
                        } exact/>
                    <Route path="/password" element={
                        <ProtectedRoute>
                            <PasswordView />
                        </ProtectedRoute>
                        } exact/>
                    <Route path={"*"} element={ <Navigate to="/" />}/>
                    {/* <Route path="/tools" element={<PdfView />} exact/>
                    <Route path="/services" element={<PdfView />} exact/> */}
                </Routes>
            </StreamingProvider>
        </AuthProvider>
    </Router>
  );
};

export default AppRouter;