import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StreamingContext } from "../hooks";


const StreamingProvider = ({ children }) => {
  const [assistantMessage, setAssistantMessage] = useState("");
  const [assistantImageURL, setAssistantImageURL] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
  }, [])


  const value = {
    isLoading: isLoading,
    setIsLoading: setIsLoading,
    assistantMessage: assistantMessage,
    setAssistantMessage: setAssistantMessage,
    assistantImageURL: assistantImageURL,
    setAssistantImageURL: setAssistantImageURL
  };

  return (
    <StreamingContext.Provider value={value}>
      {children}
    </StreamingContext.Provider>
  );
};

export default StreamingProvider