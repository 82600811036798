import { useEffect, useState } from "react";
import { useAuth, useStreaming } from "../../../utils/hooks";
import { requestOptions } from "../../../utils/fetch/sse";
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  Select,
  Row, 
  Col,
} from 'antd';
const { TextArea } = Input;
const { Option } = Select;

const normFile = (e) => {
  console.log(e)
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const UserInputToImage = () => {
  const { user, onLogout, token } = useAuth();
  const { setIsLoading, assistantImageURL, setAssistantImageURL } = useStreaming();

  async function fetchSSE(url, requestOptions) {
    setAssistantImageURL('')
    setIsLoading(true);
    const response = await fetch(url, requestOptions);
    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    const readChunk = () => {
        return reader.read().then(appendChunks);
    };

    const appendChunks = (result) => {
        const chunk = decoder.decode(result.value || new Uint8Array(), {
        stream: !result.done,
        });
        // 받아오는 data로 할 일
        console.log(chunk);
        setAssistantImageURL((assistantImageURL) => assistantImageURL + chunk);
        setIsLoading(false)

        if (!result.done) {
        return readChunk();
        }
    };
    return readChunk();
    }

  const handleSubmit = (event) => {
    let messages = []
    // if (event.userPrompt) {
    //   messages.push({
    //     "role": "system",
    //     "content": event.userPrompt
    //   })
    // }
    if (event.userInput) {
      messages.push({
        "role": "user",
        "content": event.userInput
      })
    }
    const body = {
      model: event.model,
      // model: "gpt-3.5-turbo",
      // stream: true,
      // n: 1,
      messages: messages,
    }
    fetch(process.env.REACT_APP_IMAGE_URL + "/images", {
      "method": "POST",
      "headers": {
        "Authorization": "Bearer " + token,
        "Content-Type": "application/json"
      },
      "body": JSON.stringify({
        "input": event.userInput,
        "model": event.model
      }),
      "redirect": "follow"
    })
    fetchSSE(
      process.env.REACT_APP_STREAMING_IMAGE_URL, 
      requestOptions(body)
    );
  };
  const [form] = Form.useForm();
  return (
    <div id="image_generator_form_div">
      <Form
        form={form}
        layout="vertical"
        style={{
          width: "80%"
        }}
        onFinish={handleSubmit}
      >
        <Row style={{width: "100%"}}>
          <Col style={{width: "100%"}}>
                <Form.Item label="생성할 이미지" name="userInput" rules={[{required: true, message: "생성할 이미지를 입력해주세요."}]}>
                    <TextArea placeholder="input placeholder" />
                </Form.Item>
          </Col>
        </Row>
        <Row >
          <Col style={{width: "50%"}}>
            <Form.Item
                // wrapperCol={{span: 12}}
                name="model"
                label="AI모델"
                onChange={
                  (value) => form.setFieldsValue({
                    model: value,
                  })
                }
                >
                <Select
                    placeholder="AI모델" defaultValue={"dall-e-3"}
                    >
                    <Option value="dall-e-2">dall-e-2</Option>
                    <Option value="dall-e-3">dall-e-3</Option>
                </Select>
            </Form.Item>
          </Col>
          <Col style={{width: "50%"}}>
            <Form.Item

                name="size"
                label="크기"
                >
                <Select
                    placeholder="크기" defaultValue={"1024x1024"}
                    >
                    <Option value="1024x1024">1024x1024</Option>
                </Select>
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit">이미지 생성</Button>
        {/* {assistantImageURL 
          ? 
            <Tooltip title="현재 창을 벗어나면 생성된 이미지를 확인할 수 없습니다.">
              <a download href={assistantImageURL}>
                <Button id="image_download_button">다운로드</Button> 
              </a>
            </Tooltip>
          : 
          <></>
        } */}
      </Form>
    </div>
  )
};

export default UserInputToImage;