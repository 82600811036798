import { useState, useEffect } from "react";
import { AuthContext } from "../hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import StreamingProvider from "./Streaming";

const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState({});
  const [authError, setAuthError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    isAuthenticated()
  }, [])

  const getToken = () => {
    return Cookies.get("refreshToken");
  }

  const isAuthenticated = async () => {
    if (Object.keys(authUser).length == 0) {
      const token = getToken();
  
      const customHeaders = new Headers();
      customHeaders.append("Authorization", "Bearer " + token);
  
      const requestOptions = {
        method: "GET",
        headers: customHeaders,
        redirect: "follow"
      };
  
      const response = await fetch(process.env.REACT_APP_AUTH_BASE_URL + '/user/me', requestOptions)
        .then((response) => {
          if (response.status == 200) {
            return response.json()
          } else {
            return null;
          }
        });
      if (response) {
        const { data } = response;
        setAuthUser(data);
      }
    }
  }

  const onLogin = async (employeeId, password) => {
    const customHeaders = new Headers();
    customHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "employee_id": employeeId,
      "password": password
    });

    const requestOptions = {
      method: "POST",
      headers: customHeaders,
      body: raw,
      redirect: "follow"
    };

    const response = await fetch(process.env.REACT_APP_AUTH_BASE_URL + '/user/signin', requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json()
        } else {
          setAuthError("사용자 인증 에러")
          return null;
        }
      });
    if (response) {
      const { data } = response;
      const { user, jwt } = data;
      setAuthUser(user);
      Cookies.set('refreshToken', jwt, { expires: 7, secure: true})
      const origin = location.state?.from?.pathname || '/';
      navigate(origin);
    }
  };

  const onLogout = () => {
    Cookies.remove("refreshToken");
    const origin = location.state?.from?.pathname || '/';
    navigate('/signin');
  };

  const changePassword = async ({currentPassword, newPassword}) => {
    const token = getToken();
    const customHeaders = new Headers();
    customHeaders.append("Content-Type", "application/json");
    customHeaders.append("Authorization", "Bearer " + token);

    const raw = JSON.stringify({
      "password": currentPassword,
      "new_password": newPassword,
    });

    const requestOptions = {
      method: "PUT",
      headers: customHeaders,
      body: raw,
      redirect: "follow"
    };

    const status = await fetch(process.env.REACT_APP_AUTH_BASE_URL + '/user/password', requestOptions)
      .then((response) => {
        return response.status
      });
    return status
  }

  const value = {
    user: authUser,
    token: getToken(),
    authError: authError, 
    setAuthError: setAuthError,
    onLogin: onLogin,
    onLogout: onLogout,
    changePassword: changePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider