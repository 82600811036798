import { useEffect, useState } from "react";
import { Button, Form, Input, Spin, Modal, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuth } from "../../../utils/hooks";
import userInputValidation from "../utils/validation";

const LoginView = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { isAuthLoading, onLogin, isAuthError, setIsAuthError } = useAuth();
  // const [employeeId, setEmployeeId] = useState('')
  // const [password, setPassword] = useState('')
  // const [userInputError, setUserInputError] = useState('')

  useEffect(() => {
    if (isAuthError) {
        Modal.error({
        title: '사용자 인증 에러',
        content: '사번과 비밀번호를 확인해주세요'
      }) 
      setIsAuthError(false)
    }
  }, [isAuthError])

  return (
    <div id="signin_div">
    <h1>GS파워 GenAI 플랫폼(안)</h1>
    {isAuthLoading ? <Spin indicator={
      <LoadingOutlined
        style={{
          fontSize: 24,
        }}
        spin
      />
    }/> : <Form
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      autoComplete="off"
      onFinish= {({employeeId, password}) => {
        onLogin(employeeId, password)
      }}
    >
      <Form.Item
        label="사번"
        name="employeeId"
        rules={[
          {
            required: true,
            message: '사번을 입력하세요',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="비밀번호"
        name="password"
        rules={[
          {
            required: true,
            message: '비밀번호를 입력하세요',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" style={{background: 'linear-gradient(180deg, #007871 0%, #009999 100%)'}}>
          사용자 인증
        </Button>
    </Form.Item>
    </Form>
  }
    </div>
  );
};

export default LoginView;