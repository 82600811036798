import { Empty, Button, Modal, Space } from 'antd';
import {
  SyncOutlined,
} from '@ant-design/icons';

const warning = () => {
    Modal.warning({
        title: '오픈 예정',
        content: '프롬프트 준비 중입니다.',
    });
};

const InProgressView = () => {
    return (
        <Empty id="empty_div" description={false}>
            <Button onClick={warning}>준비 중</Button>
        </Empty>
    )};
export default InProgressView;