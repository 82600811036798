const flatten = (obj = {}) => Object.keys(obj || {}).reduce((acc, cur) => {
  if (obj[cur].children) {
    obj[cur].children.forEach(child => {
        acc[child.key] = { 'label': child.label, 'url': child.url }
    });
  } else { acc[obj[cur].key] = { 'label': obj[cur].label, 'url': obj[cur].url } }
  return acc
}, {})

const setItem = (label, key, url, icon, children) => {
  return {
    label,
    key,
    url,
    icon,
    children,
  };
}

export {
    flatten,
    setItem
}