import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Flex,
  Button,
  Modal
} from 'antd';
import { useAuth } from "../../../utils/hooks";
import { useFrame } from '../../../utils/store.js';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const App = () => {
  const { changePassword } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { setIframeSrc } = useFrame();

  const onFinish = async (values) => {
    const responseStatus = await changePassword(values)
    if (responseStatus == 200){
        Modal.success({
            title: '비밀번호 변경',
            content: '비밀번호가 변경되었습니다.'
        }) 
        setIframeSrc("/aichat")
        // const origin = location.state?.from?.pathname || '/';
        // navigate(origin)
    } else {
        Modal.error({
            title: '사용자 인증 에러',
            content: '현재 비밀번호가 일치하지 않습니다.'
        }) 
    }
  };

  return (
    <Flex style={{display: "flex", justifyContent: "center", alignContent: "center", height: "100vh"}}>
        <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        initialValues={{
            residence: ['zhejiang', 'hangzhou', 'xihu'],
            prefix: '86',
        }}
        style={{
            maxWidth: 600,
            margin: "auto"
        }}
        scrollToFirstError
        >
        <Form.Item
            name="currentPassword"
            label="현재 비밀번호"
            rules={[
            {
                required: true,
                message: '현재 비밀번호를 입력해주세요',
            },
            ]}
            hasFeedback
        >
            <Input.Password />
        </Form.Item>
        
        <Form.Item
            name="newPassword"
            label="비밀번호 변경"
            rules={[
            {
                required: true,
                message: '새로운 비밀번호를 입력해주세요',
            },
            {
                required: true,
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*]).{8,16}$/,
                message: '8자리 이상, 16자리 이하, 영문 대소문자 포함, 특수문자(!@#$%^&*) 중 하나 포함',
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                if (getFieldValue("currentPassword") && getFieldValue('currentPassword') === value) {
                    return Promise.reject(new Error('현재 비밀번호와 변경할 비밀번호가 동일합니다.'));
                    }
                return Promise.resolve();
                },
            }),
            ]}
            hasFeedback
        >
            <Input.Password />
        </Form.Item>

        <Form.Item
            name="confirmPassword"
            label="비밀번호 확인"
            dependencies={['password']}
            hasFeedback
            rules={[
            {
                required: true,
                message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                }
                return Promise.reject(new Error('변경할 비밀번호와 일치하지 않습니다.'));
                },
            }),
            ]}
        >
            <Input.Password />
        </Form.Item>
        <Form.Item style={{display: "flex", justifyContent: "end"}}>
            <Button type="primary" htmlType="submit" style={{background: 'linear-gradient(180deg, #007871 0%, #009999 100%)'}}>
                변경하기
            </Button>
        </Form.Item>
        </Form>
    </Flex>
  );
};
export default App;