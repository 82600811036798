import Cookies from 'js-cookie';

const requestOptions = (body) =>{
  let customHeader = {
      "Authorization": "Bearer " + Cookies.get('refreshToken'),
      "Content-Type": "application/json"
    }
  return ({
    method: "POST",
    redirect: "follow",
    headers: customHeader,
    body: JSON.stringify(body)
  })
}

export {
  requestOptions
}